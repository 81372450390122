import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PermissionRoute from './distrokid/routes/PermissionRoute';
import { Permissions } from './distrokid/routes/permissions';

import Loader from '../app/distrokid/tools/Loader';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Widgets = lazy(() => import('./widgets/Widgets'));

const KanbanBoard = lazy(() => import('./apps/KanbanBoard'));
const Tickets = lazy(() => import('./apps/Tickets'));
const Chats = lazy(() => import('./apps/Chats'));
const TodoList = lazy(() => import('./apps/TodoList'));

const Accordions = lazy(() => import('./basic-ui/Accordions'));
const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Badges = lazy(() => import('./basic-ui/Badges'));
const Breadcrumbs = lazy(() => import('./basic-ui/Breadcrumbs'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Modals = lazy(() => import('./basic-ui/Modals'));
const Progress = lazy(() => import('./basic-ui/Progress'));
const Paginations = lazy(() => import('./basic-ui/Paginations'));
const TabsPage = lazy(() => import('./basic-ui/TabsPage'));
const Typography = lazy(() => import('./basic-ui/Typography'));
const Popups = lazy(() => import('./basic-ui/Popups'));

const Dragula = lazy(() => import('./advanced-ui/Dragula'));
const Clipboard = lazy(() => import('./advanced-ui/Clipboards'));
const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
const Sliders = lazy(() => import('./advanced-ui/Sliders'));
const Carousel = lazy(() => import('./advanced-ui/Carousel'));
const Loaders = lazy(() => import('./advanced-ui/Loaders'));
const TreeView = lazy(() => import('./advanced-ui/TreeView'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
const Wizard = lazy(() => import('./form-elements/Wizard'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const ReactTable = lazy(() => import('./tables/ReactTable'));
const SortableTable = lazy(() => import('./tables/SortableTable'));

const VectorMap = lazy(() => import('./maps/VectorMap'));
const SimpleMap = lazy(() => import('./maps/SimpleMap'));

const Notifications = lazy(() => import('./notifications/Notifications'));

const Mdi = lazy(() => import('./icons/Mdi'));
const FlagIcons = lazy(() => import('./icons/FlagIcons'));
const FontAwesome = lazy(() => import('./icons/FontAwesome'));
const SimpleLine = lazy(() => import('./icons/SimpleLine'));
const Themify = lazy(() => import('./icons/Themify'));
const TypIcons = lazy(() => import('./icons/TypIcons'));

const TextEditors = lazy(() => import('./text-editors/TextEditors'));
const CodeEditor = lazy(() => import('./code-editor/CodeEditor'));

const ChartJs = lazy(() => import('./charts/ChartJs'));
const C3Charts = lazy(() => import('./charts/C3Charts'));
const Chartist = lazy(() => import('./charts/Chartist'));
const GoogleCharts = lazy(() => import('./charts/GoogleCharts'));
const SparkLineCharts = lazy(() => import('./charts/SparkLineCharts'));
const GuageChart = lazy(() => import('./charts/GuageChart'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const LandingPage = lazy(() => import('./general-pages/LandingPage'));
const BlankPage = lazy(() => import('./general-pages/BlankPage'));
const Profile = lazy(() => import('./distrokid/Profile'));
const Faq = lazy(() => import('./general-pages/Faq'));
const Faq2 = lazy(() => import('./general-pages/Faq2'));
const NewsGrid = lazy(() => import('./general-pages/NewsGrid'));
const Timeline = lazy(() => import('./general-pages/Timeline'));
const SearchResults = lazy(() => import('./general-pages/SearchResults'));
const Portfolio = lazy(() => import('./general-pages/Portfolio'));
const UserListing = lazy(() => import('./general-pages/UserListing'));

const Invoice = lazy(() => import('./ecommerce/Invoice'));
const Pricing = lazy(() => import('./ecommerce/Pricing'));
const ProductCatalogue = lazy(() => import('./ecommerce/ProductCatalogue'));
const ProjectList = lazy(() => import('./ecommerce/ProjectList'));

const Email = lazy(() => import('./apps/Email'));
const Calendar = lazy(() => import('./apps/Calendar'));
const Gallery = lazy(() => import('./apps/Gallery'));


// CONST VALIDE
const Login = lazy(() => import('./distrokid/Login'));
const logoutUser = lazy(() => import('./distrokid/API/v1/ApiService'));
const Register = lazy(() => import('./distrokid/Register'));

//PLATEFORMEMANAGEMENT
const PlateformeManagement = lazy(() => import('./distrokid/plateformeManagement/info-compte'));
const GererLesComptes = lazy(() => import('./distrokid/plateformeManagement/gerer-les-comptes'));

// JSON ARTISTE
const JsonArtiste = lazy(() => import('./distrokid/json_artiste/gerer_json_artiste'));

// GERER EMAIL
const GererEmail = lazy(() => import('./distrokid/email/gerer-email'));


// GAME
const TopChrono = lazy(() => import('./game/TopChrono'));

// CONST VALIDE

class AppRoutes extends Component {
	render() {
		return (
			<Suspense fallback={<Loader />}>
				<Switch>
					{/* ROUTE VALIDE */}
					<Route exact path="/dashboard" component={Dashboard} />

					<PermissionRoute path="/login" component={Login} requiredPermissions={Permissions.NOTCONNECTED} />
					<PermissionRoute path="/register" component={Register} requiredPermissions={Permissions.NOTCONNECTED} />
					<PermissionRoute path="/logout" component={logoutUser} requiredPermissions={Permissions.CONNECTED} />

					{/* Utilisateur */}
					<PermissionRoute path="/general-pages/profile" component={Profile} requiredPermissions={Permissions.CONNECTED} />


					{/* PLATEFORMEMANAGEMENT */}
					<PermissionRoute path="/distrokid/plateforme-management/info-compte" component={PlateformeManagement} requiredPermissions={Permissions.PLATEFORMEMANAGEMENT} />
					<PermissionRoute path="/distrokid/plateforme-management/gerer-les-comptes" component={GererLesComptes} requiredPermissions={Permissions.PLATEFORMEMANAGEMENT} />

					{/* JSON ARTISTE */}
					<PermissionRoute path="/distrokid/json-artiste/gerer-json-artiste" component={JsonArtiste} requiredPermissions={Permissions.JSONARTISTE} />

					{/* GERER EMAIL */}
					<PermissionRoute path="/distrokid/email/gerer-email" component={GererEmail} requiredPermissions={Permissions.GERER_EMAIL} />

					{/* ERROR PAGES */}
					<Route path="/error-pages/error-404" component={Error404} />
					<Route path="/error-pages/error-500" component={Error500} />

					{/* GAME */}
					<Route path="/game/tc" component={TopChrono} />

					{/* ROUTE VALIDE */}

					<Route exact path="/widgets" component={Widgets} />

					<Route exact path="/apps/kanban-board" component={KanbanBoard} />
					<Route exact path="/apps/todo-list" component={TodoList} />
					<Route exact path="/apps/tickets" component={Tickets} />
					<Route exact path="/apps/chats" component={Chats} />

					<Route path="/basic-ui/accordions" component={Accordions} />
					<Route path="/basic-ui/buttons" component={Buttons} />
					<Route path="/basic-ui/badges" component={Badges} />
					<Route path="/basic-ui/breadcrumbs" component={Breadcrumbs} />
					<Route path="/basic-ui/dropdowns" component={Dropdowns} />
					<Route path="/basic-ui/modals" component={Modals} />
					<Route path="/basic-ui/progressbar" component={Progress} />
					<Route path="/basic-ui/pagination" component={Paginations} />
					<Route path="/basic-ui/tabs" component={TabsPage} />
					<Route path="/basic-ui/typography" component={Typography} />
					<Route path="/basic-ui/popups" component={Popups} />

					<Route path="/advanced-ui/dragula" component={Dragula} />
					<Route path="/advanced-ui/clipboard" component={Clipboard} />
					<Route path="/advanced-ui/context-menu" component={ContextMenu} />
					<Route path="/advanced-ui/sliders" component={Sliders} />
					<Route path="/advanced-ui/carousel" component={Carousel} />
					<Route path="/advanced-ui/loaders" component={Loaders} />
					<Route path="/advanced-ui/tree-view" component={TreeView} />

					<Route path="/form-Elements/basic-elements" component={BasicElements} />
					<Route path="/form-Elements/advanced-elements" component={AdvancedElements} />
					<Route path="/form-Elements/wizard" component={Wizard} />

					<Route path="/tables/basic-table" component={BasicTable} />
					<Route path="/tables/react-table" component={ReactTable} />
					<Route path="/tables/sortable-table" component={SortableTable} />

					<Route path="/maps/vector-map" component={VectorMap} />
					<Route path="/maps/simple-map" component={SimpleMap} />

					<Route path="/notifications" component={Notifications} />

					<Route path="/icons/mdi" component={Mdi} />
					<Route path="/icons/flag-icons" component={FlagIcons} />
					<Route path="/icons/font-awesome" component={FontAwesome} />
					<Route path="/icons/simple-line" component={SimpleLine} />
					<Route path="/icons/themify" component={Themify} />
					<Route path="/icons/typicons" component={TypIcons} />

					<Route path="/text-editors" component={TextEditors} />
					<Route path="/code-editor" component={CodeEditor} />

					<Route path="/icons/themify" component={Themify} />

					<Route path="/charts/chart-js" component={ChartJs} />
					<Route path="/charts/c3-chart" component={C3Charts} />
					<Route path="/charts/chartist" component={Chartist} />
					<Route path="/charts/google-charts" component={GoogleCharts} />
					<Route path="/charts/sparkline-charts" component={SparkLineCharts} />
					<Route path="/charts/guage-chart" component={GuageChart} />

					<Route path="/user-pages/lockscreen" component={Lockscreen} />

					<Route path="/general-pages/blank-page" component={BlankPage} />
					<Route path="/general-pages/landing-page" component={LandingPage} />
					<Route path="/general-pages/faq-1" component={Faq} />
					<Route path="/general-pages/faq-2" component={Faq2} />
					<Route path="/general-pages/news-grid" component={NewsGrid} />
					<Route path="/general-pages/timeline" component={Timeline} />
					<Route path="/general-pages/search-results" component={SearchResults} />
					<Route path="/general-pages/portfolio" component={Portfolio} />
					<Route path="/general-pages/user-listing" component={UserListing} />

					<Route path="/ecommerce/invoice" component={Invoice} />
					<Route path="/ecommerce/pricing" component={Pricing} />
					<Route path="/ecommerce/product-catalogue" component={ProductCatalogue} />
					<Route path="/ecommerce/project-list" component={ProjectList} />

					<Route path="/apps/email" component={Email} />
					<Route path="/apps/calendar" component={Calendar} />
					<Route path="/apps/gallery" component={Gallery} />

					<Redirect to="/login" />
				</Switch>
			</Suspense>
		);
	}
}

export default AppRoutes;