import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { IsAuthenticated } from '../distrokid/API/v1/isAuthenticated';
import { logoutUser } from '../distrokid/API/v1/ApiService';

class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  render() {
    return (
      <IsAuthenticated.Consumer>
        {value => (
          <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo" to="/"><img src={require('../../assets/images/logo.png')} alt="logo" /></Link>
              <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.png')} alt="logo" /></Link>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-stretch">
              <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                <span className="mdi mdi-menu"></span>
              </button>

              {value.isAuth ? (
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-profile">
                    <Dropdown>
                      <Dropdown.Toggle className="nav-link">
                        <div className="nav-profile-img">
                          <img src={require("../../assets/images/faces/face1.jpg")} alt="user" />
                          <span className="availability-status online"></span>
                        </div>
                        <div className="nav-profile-text">
                          <p className="mb-1 text-black"><Trans>{value.pseudo}</Trans></p>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="navbar-dropdown">
                        <Dropdown.Item as="div">
                          <Link to="/general-pages/profile" className="dropdown-item">
                            <i className="mdi mdi-cached me-2 text-success"></i>
                            <Trans>Paramètres</Trans>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="!#" onClick={evt => { evt.preventDefault(); logoutUser(); }}>
                          <i className="mdi mdi-logout me-2 text-primary"></i>
                          <Trans>Déconnexion</Trans>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-logout d-none d-lg-block">
                    <Link className="nav-link" to="/login">
                      <i className="mdi mdi-power"></i>
                    </Link>
                  </li>
                </ul>
              )}
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
                <span className="mdi mdi-menu"></span>
              </button>
            </div>
          </nav>
        )}
      </IsAuthenticated.Consumer>
    );
  }
}

export default Navbar;
